import styled from 'styled-components';

export const AboutSection = styled.section`
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  .bio {
    h1 {
      margin-top: 0;
      font-family: var(--fontSecondary);
      font-size: 3rem;
    }
    a {
      display: inline-block;
      margin-top: 1.75rem;
      padding: 1.25rem 2.5rem;
      border-radius: 1rem;
      font-family: var(--fontSecondary);
      letter-spacing: 2px;
      text-transform: uppercase;
      color: var(--black);
      background: var(--yellow);
      border: 0;
      cursor: pointer;
      transition: background 0.2s ease-out;
      &:hover {
        background: #d2ad00;
      }
    }
  }
  .gatsby-image-wrapper {
    max-height: 550px;
    border-radius: 1.5rem;
  }

  @media (max-width: 991.98px) {
    grid-template-columns: 1fr;
    div:last-child {
      grid-row: 1;
    }
  }
  @media (max-width: 575.98px) {
    margin-top: 2rem;
  }
`;

export const SkillsSection = styled.section`
  margin-top: 4rem;
  h2 {
    display: inline-block;
    margin-bottom: 1rem;
  }
  .grid {
    margin-bottom: 11rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }

  @media (max-width: 767.98px) {
    .grid {
      grid-template-columns: 1fr;
    }
  }
`;

export const BackgroundList = styled.ul`
  margin: 0;
  padding: 0;
  li {
    margin-bottom: 1.5rem;
    list-style: none;
    p.location,
    h3,
    time {
      font-size: 1.6rem;
      margin: 0;
      font-family: var(--fontSecondary);
    }
    time {
      opacity: 0.67;
    }
  }
`;
export const SkillsList = styled.ul`
  margin: 0;
  padding: 0;
  max-width: 600px;
  margin-top: 0;
  columns: 2;
  li {
    font-family: var(--fontSecondary);
    list-style: none;
  }
`;

import styled from 'styled-components';

const SectionTitle = styled.h2`
  position: relative;
  margin: 0;
  display: inline;
  z-index: 1;
  font-family: var(--fontSecondary);
  font-size: 3rem;
  font-weight: 700;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2.2rem;
    background: var(--yellow);
    z-index: -1;
    top: 0.9rem;
    left: 0.2rem;
  }
`;

export default SectionTitle;

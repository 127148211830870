import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import ReactMarkdown from 'react-markdown';
import SEO from '../components/SEO';
import {
  AboutSection,
  SkillsSection,
  BackgroundList,
  SkillsList,
} from '../styles/AboutStyles';
import Container from '../styles/Container';
import SectionTitle from '../styles/SectionTitle';

export const query = graphql`
  query {
    settings: sanitySiteSettings(_id: { eq: "website" }) {
      _id
      name
      bioContent
    }
    headshot: file(relativePath: { eq: "headshot.jpeg" }) {
      childImageSharp {
        fluid(maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const skills = [
  'HTML/CSS',
  'Javascript',
  'Laravel',
  'PHP',
  'React.js',
  'Gatsby',
  'Node.js',
  'Express.js',
  'Vue.js',
  'PHPUnit',
  'Laravel Dusk',
  'GraphQL',
  'REST APIs',
  'Bootstrap',
  'Tailwind',
  'MySQL',
  'MongoDB',
];

const About = ({ data }) => (
  <>
    <SEO title="👨🏻‍💻 About me!" />
    <div>
      <Container>
        <AboutSection>
          <div className="bio">
            <ReactMarkdown>{data.settings.bioContent}</ReactMarkdown>
            <Link to="/#contact">Get in touch</Link>
          </div>
          <div>
            <Img fluid={data.headshot.childImageSharp.fluid} />
          </div>
        </AboutSection>

        <SkillsSection>
          <div className="grid">
            <div>
              <SectionTitle>Background</SectionTitle>
              <BackgroundList>
                <li>
                  <time>2017 - Present</time>
                  <h3>Full-Stack Developer</h3>
                  <p className="location">Brain Box Labs * Toronto, ON</p>
                </li>
                <li>
                  <time>2015 - 2017</time>
                  <h3>Web Design &amp; Development</h3>
                  <p className="location">Durham College * Oshawa, ON</p>
                </li>
              </BackgroundList>
            </div>
            <div>
              <SectionTitle>Skills</SectionTitle>
              <SkillsList className="skill-list">
                {skills.map(skill => (
                  <li key={skill}>{skill}</li>
                ))}
              </SkillsList>
            </div>
          </div>
        </SkillsSection>
      </Container>
    </div>
  </>
);

export default About;
